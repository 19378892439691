export const seedGraphWrapperHeightTopEmp = 369;
export const seedGraphWrapperHeightMajors = 484;

export const initialListingStateDataListing = {
  loader: true,
  data: null,
  selectedFilters: {
    degree: null,
    year: null,
    nationality: null,
    major: null,
    employment_type: null,
    country: null,
  },
  page: 1,
  per_page: 10,
  total_pages: 1,
  total_opts: 0,
  overall_counts: 0,
  order_by: null,
  order_direction: null,
};

export const initialStateTopEmployerGraph = {
  loader: true,
  data_set: {
    labels: null,
    data: null,
  },
  graphHeight: seedGraphWrapperHeightTopEmp,
  selectedFilters: {
    year: null,
    nationality: null,
    major: null,
    degree: null,
    employment_type: null,
    country: null,
  },
};

export const initialStateDegreesGraph = {
  loader: true,
  data_set: {
    labels: null,
    data: null,
  },
  selectedFilters: {
    year: null,
    nationality: null,
    major: null,
  },
};

export const initialStateNationalityGraph = {
  loader: true,
  data_set: null,
  selectedFilters: {
    year: null,
    major: null,
    degree: null,
  },
};

export const initialStateMajorsGraph = {
  loader: true,
  data_set: {
    labels: null,
    data: null,
  },
  graphHeight: seedGraphWrapperHeightMajors,
  selectedFilters: {
    year: null,
    nationality: null,
    degree: null,
    employment_type: null,
  },
};

export const dataTableHeaders = [
  {
    name: "Company",
    isSort: true,
  },
  {
    name: "Job Title",
    isSort: true,
  },
  {
    name: "Degree",
    isSort: true,
  },
  {
    name: "Major",
    isSort: true,
  },
  {
    name: "Job location",
    isSort: true,
  },
  {
    name: "Year joined",
    isSort: true,
  },
  {
    name: "Type",
    isSort: true,
  },
  {
    name: "Nationality",
    isSort: true,
  },
];
