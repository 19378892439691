import React from "react";
import { TextField } from "@mui/material";

import styles from "./SearchTextBox.module.scss";
import { createModuleStyleExtractor } from "../../utils/css";

import searchSimpleIconOpened from "../../assets/svg/search-open.svg";
import searchSimpleIconClosed from "../../assets/svg/search-closed.svg";

const cx = createModuleStyleExtractor(styles);

export default function SearchTextBox({
  openTextBox = false,
  placeholder = "Search",
  isDisabled = false,
  value = "",
  onChange = () => {},
  setOpenTextBox = () => {},
}) {
  return (
    <div
      className={cx([
        "searchTextBox",
        isDisabled ? "searchTextBox--disabled" : "",
      ])}
    >
      <img
        src={openTextBox ? searchSimpleIconOpened : searchSimpleIconClosed}
        alt="search"
        className={cx("searchTextBox__icon")}
        onClick={() => setOpenTextBox((current) => !current)}
      />
      {openTextBox && (
        <div className={cx("searchTextBox__text-box")}>
          <TextField
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
          />
        </div>
      )}
    </div>
  );
}
