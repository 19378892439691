import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import MuiTable from "../../../../components/atoms/MuiTable/MuiTable";
import DropdownCustom from "../../../../components/DropdownCustom/DropdownCustom";
import { createModuleStyleExtractor } from "../../../../utils/css";
import { getGraphTableData } from "../../../../services/employmentDataService";
import {
  dataTableHeaders,
  initialListingStateDataListing,
} from "../Utils/EmploymentDataConstants";
import {
  getValidSearchText,
  createFriendlyTitle,
  getCapitalizedHeader,
  parseEmploymentType,
  parseEmploymentPayload,
  numberWithCommas,
} from "../../../../helper/helper";
import styles from "./EmploymentDataTable.module.scss";
import SearchTextBox from "../../../../components/SearchTextBox/SearchTextBox";

import FilterIcon from "../../../../assets/Icons/FilterIcon";
import CloseIconV2 from "../../../../assets/svg/close-icon-v2.svg";

const cx = createModuleStyleExtractor(styles);

export default function EmploymentDataTable({
  widget = false,
  tableState,
  setTableState = () => {},
  tableSearch = "",
  setTableSearch = () => {},
  openFilter,
  toggleFilter = () => {},
  filters,
  dataSchool = null,
}) {
  const [openTextBox, setOpenTextBox] = useState(false);

  // Call table data api upon filter change change and component load
  useEffect(() => {
    getOPTListingData({
      page: 1,
      per_page: 10,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState?.selectedFilters, dataSchool]);

  // This opens search textbox if search is applied
  useEffect(() => {
    if (tableSearch.length > 0 && openTextBox === false) {
      setOpenTextBox(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableSearch]);

  // This opens filter UI if filters are applied
  useEffect(() => {
    if (
      (tableState?.selectedFilters?.country?.value ||
        tableState?.selectedFilters?.degree?.value ||
        tableState?.selectedFilters?.employment_type?.value ||
        tableState?.selectedFilters?.major?.value ||
        tableState?.selectedFilters?.nationality?.value ||
        tableState?.selectedFilters?.year?.value) &&
      openFilter === false
    ) {
      toggleFilter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState?.selectedFilters]);

  // Debounce search callback
  const debouncedSearchCallback = useDebouncedCallback(async (value) => {
    setTableState((current) => ({
      ...current,
      loader: true,
    }));
    getOPTListingData({ search: value });
  }, 1000);

  const handlePaginationChange = (page, per_page) => {
    getOPTListingData({ search: null, page, per_page });
  };

  const getOPTListingData = async ({
    search = null,
    page = null,
    per_page = null,
    order_by = null,
    order_direction = null,
  }) => {
    try {
      let response = {};
      if (widget) {
        // Dummy sample data for widget
        response = {
          success: true,
          message: "Success",
          data: {
            opts: [
              {
                id: 839,
                school_id: 12295,
                company: "Deolittle & Touche LLP",
                job_title: "Cyber Advisory Analyst",
                year: "2022",
                major: "Information Technology Project Management",
                degree: "Associate",
                nationality: "Canada",
                created_at: "2023-12-15T09:03:57.000Z",
                updated_at: "2023-12-15T09:03:57.000Z",
                employment_type: "OPT",
              },
              {
                id: 840,
                school_id: 12295,
                company: "Devior Software Solutions",
                job_title: "Data Engineer",
                year: "2022",
                major: "Information Technology Project Management",
                degree: "Degree\r",
                nationality: "India",
                created_at: "2023-12-15T09:03:57.000Z",
                updated_at: "2023-12-15T09:03:57.000Z",
                employment_type: "OPT",
              },
              {
                id: 841,
                school_id: 12295,
                company: "Redifcard Technologies LLC",
                job_title: "Data Analyst",
                year: "2022",
                major: "Information Technology Project Management",
                degree: "Bachelor",
                nationality: "India",
                created_at: "2023-12-15T09:03:57.000Z",
                updated_at: "2023-12-15T09:03:57.000Z",
                employment_type: "OPT",
              },
            ],
          },
        };
      } else {
        const { major, year, nationality, degree, employment_type, country } =
          tableState?.selectedFilters || {};

        response = await getGraphTableData({
          dataSchool: dataSchool,
          page: page || tableState.page,
          per_page: per_page || tableState.per_page,
          search: search?.trim() || tableSearch?.trim(),
          major: major?.value,
          year: year?.value,
          nationality: nationality?.value,
          degree: degree?.value,
          employment_type: parseEmploymentPayload(employment_type?.value),
          country: country?.value,
          order_by: order_by || tableState?.order_by,
          order_direction: order_direction || tableState?.order_direction,
        });
      }
      if (response?.success && response?.data?.opts?.length > 0) {
        // Process listing data
        let _data = [];
        for (let i = 0; i < response.data.opts.length; i++) {
          const dataRecord = response?.data?.opts[i] || {};
          _data.push([
            getCapitalizedHeader(dataRecord?.company),
            getCapitalizedHeader(dataRecord?.job_title),
            getCapitalizedHeader(dataRecord?.degree),
            createFriendlyTitle(dataRecord?.major),
            createFriendlyTitle(dataRecord?.country),
            dataRecord?.year === "0" ? "-" : dataRecord?.year,
            parseEmploymentType(dataRecord?.employment_type),
            createFriendlyTitle(dataRecord?.nationality),
          ]);
        }
        setTableState((current) => ({
          ...current,
          loader: false,
          data: _data,
          page: parseInt(response?.data?.current_page),
          per_page: per_page || tableState.per_page,
          total_pages: response?.data?.total_pages,
          total_opts: response?.data?.total_opts,
          overall_counts: response?.data?.overall_counts,
        }));
        // Scroll to top
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      setTableState((current) => ({
        ...current,
        loader: false,
        data: null,
        page: 1,
        per_page: 10,
        total_pages: 1,
        total_opts: 0,
        overall_counts: 0,
      }));
    } catch (error) {
      setTableState((current) => ({
        ...current,
        loader: false,
        data: null,
        page: 1,
        per_page: 10,
        total_pages: 1,
        total_opts: 0,
        overall_counts: 0,
      }));
    }
  };

  const handleClearAllFilter = () => {
    if (
      tableSearch?.length > 0 &&
      tableState?.selectedFilters?.degree === null &&
      tableState?.selectedFilters?.major === null &&
      tableState?.selectedFilters?.nationality === null &&
      tableState?.selectedFilters?.year === null &&
      tableState?.selectedFilters?.employment_type === null &&
      tableState?.selectedFilters?.country === null
    ) {
      setTableSearch("");
      debouncedSearchCallback("");
    } else {
      tableSearch?.length > 0 && setTableSearch("");
      setTableState((current) => ({
        ...current,
        selectedFilters: initialListingStateDataListing.selectedFilters,
      }));
    }
  };

  const changeSortingFn = (orderByName, order_direction) => {
    // Process propertyName to keyname for BE API
    let order_by = null;
    if (orderByName === "Job Title") order_by = "job_title";
    else if (orderByName === "Type") order_by = "employment_type";
    else if (orderByName === "Job location") order_by = "country";
    else if (orderByName === "Year joined") order_by = "year";
    else order_by = orderByName?.toLowerCase();

    // Update table state
    setTableState((current) => ({
      ...current,
      order_by,
      order_direction,
    }));

    getOPTListingData({
      search: tableSearch,
      page: tableState.page,
      per_page: tableState.per_page,
      order_by,
      order_direction,
    });
  };

  //  CPT, OPT and STEM OPT are only relevant for US
  const onEmploymentTypeChange = (option) => {
    const isUSSpecific =
      option?.value &&
      (option.value === "CPT" ||
        option.value === "OPT" ||
        option.value === "STEM OPT");
    // clearing residue country value conditionally

    setTableState((current) => ({
      ...current,
      selectedFilters: {
        ...current.selectedFilters,
        country: isUSSpecific ? null : current.selectedFilters.country,
        employment_type: option,
      },
    }));
  };

  return (
    <div
      className={cx([
        "m-emp-table",
        ...(widget ? ["m-emp-table--widget"] : []),
      ])}
    >
      {!widget && (
        <>
          <div className={cx("m-emp-table__header")}>
            <SearchTextBox
              openTextBox={openTextBox}
              setOpenTextBox={setOpenTextBox}
              value={tableSearch}
              onChange={(e) => {
                const text = getValidSearchText(e.target.value);
                setTableSearch(text);
                debouncedSearchCallback(text);
              }}
              isDisabled={tableState.loader}
            />
            <div
              className={cx("m-emp-table__header__filter-icon")}
              onClick={() => toggleFilter((current) => !current)}
            >
              <FilterIcon fill={openFilter} />
            </div>
            {!tableState.loader && (
              <p className={cx("m-emp-table__header__filter-text")}>
                {`Filtering ${numberWithCommas(
                  tableState?.total_opts || 0
                )} out of ${numberWithCommas(tableState?.overall_counts)}`}
              </p>
            )}
            {(tableSearch?.length > 0 ||
              tableState?.selectedFilters?.degree !== null ||
              tableState?.selectedFilters?.major !== null ||
              tableState?.selectedFilters?.nationality !== null ||
              tableState?.selectedFilters?.year !== null ||
              tableState?.selectedFilters?.employment_type !== null ||
              tableState?.selectedFilters?.country !== null) && (
              <p
                className={cx("m-emp-table__header__filter-clear-all")}
                onClick={handleClearAllFilter}
              >
                Clear all
              </p>
            )}
          </div>
          {openFilter && (
            <div className={cx("m-emp-table__filters")}>
              <div className={cx("m-emp-table__filters__header")}>
                <h4>Filter by</h4>
                <img
                  src={CloseIconV2}
                  alt="close"
                  onClick={() => toggleFilter(false)}
                />
              </div>
              <div className={cx("m-emp-table__filters__body")}>
                <div className={cx("m-emp-table__filters__body__filter")}>
                  <DropdownCustom
                    name="degree"
                    placeholder="Degree"
                    value={tableState?.selectedFilters?.degree}
                    justifyContent="left"
                    options={filters?.degree}
                    onChange={(option) =>
                      setTableState((current) => ({
                        ...current,
                        selectedFilters: {
                          ...current.selectedFilters,
                          degree: option,
                        },
                      }))
                    }
                    isClearable
                  />
                </div>
                <div className={cx("m-emp-table__filters__body__filter")}>
                  <DropdownCustom
                    name="major"
                    placeholder="Major"
                    value={tableState?.selectedFilters?.major}
                    justifyContent="left"
                    options={filters?.major}
                    onChange={(option) =>
                      setTableState((current) => ({
                        ...current,
                        selectedFilters: {
                          ...current.selectedFilters,
                          major: option,
                        },
                      }))
                    }
                    isClearable
                  />
                </div>
                <div className={cx("m-emp-table__filters__body__filter")}>
                  <DropdownCustom
                    name="year"
                    placeholder="Year"
                    value={tableState?.selectedFilters?.year}
                    justifyContent="left"
                    options={filters?.year}
                    onChange={(option) =>
                      setTableState((current) => ({
                        ...current,
                        selectedFilters: {
                          ...current.selectedFilters,
                          year: option,
                        },
                      }))
                    }
                    isClearable
                  />
                </div>
                {tableState?.selectedFilters?.employment_type?.value ===
                  "CPT" ||
                tableState?.selectedFilters?.employment_type?.value === "OPT" ||
                tableState?.selectedFilters?.employment_type?.value ===
                  "STEM OPT" ? (
                  <></>
                ) : (
                  <div className={cx("m-emp-table__filters__body__filter")}>
                    <DropdownCustom
                      name="country"
                      placeholder="Job Location"
                      value={tableState?.selectedFilters?.country}
                      justifyContent="left"
                      options={filters?.country}
                      onChange={(option) =>
                        setTableState((current) => ({
                          ...current,
                          selectedFilters: {
                            ...current.selectedFilters,
                            country: option,
                          },
                        }))
                      }
                      isClearable
                    />
                  </div>
                )}
                <div className={cx("m-emp-table__filters__body__filter")}>
                  <DropdownCustom
                    name="Employment Type"
                    placeholder="Type"
                    value={tableState?.selectedFilters?.employment_type}
                    justifyContent="left"
                    options={filters?.employment_type}
                    onChange={onEmploymentTypeChange}
                    isClearable
                  />
                </div>
                <div className={cx("m-emp-table__filters__body__filter")}>
                  <DropdownCustom
                    name="nationality"
                    placeholder="Nationality"
                    value={tableState?.selectedFilters?.nationality}
                    justifyContent="left"
                    options={filters?.nationality}
                    onChange={(option) =>
                      setTableState((current) => ({
                        ...current,
                        selectedFilters: {
                          ...current.selectedFilters,
                          nationality: option,
                        },
                      }))
                    }
                    isClearable
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className={cx("m-emp-table__body")}>
        <MuiTable
          tableHeaders={dataTableHeaders}
          tableData={tableState.data}
          pagination={widget ? false : true}
          handlePaginationChange={handlePaginationChange}
          page={tableState.page}
          total_pages={tableState?.total_pages}
          tableLoader={tableState?.loader}
          per_page={tableState?.per_page}
          noDataTextHeader="Looks like there are no employment data with this search."
          noDataTextBody="Try removing some filters to expand your search and get more results."
          changeSortingFn={changeSortingFn}
        />
      </div>
    </div>
  );
}
